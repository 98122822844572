
<template>
  <div class="user-edit-tab-info">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="firstName">First name</label>
                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                v-validate="'required|alpha_spaces'" placeholder="First name" name="first_name" />
                <span class="text-danger font-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="LastName">Last name</label>
                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                v-validate="'required|alpha_spaces'" placeholder="Last Name" name="last_name" />
                <span class="text-danger font-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
            </div>
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="inputEmail">Email Address</label>
                <input id="inputEmail" :readonly="!!data.is_verified" label="Email" v-model="data.email" type="email" v-validate="'required|email'" name="email" class="form-control"/>
                <span class="text-danger font-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="inputMobile">Mobile</label>
                <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
                v-validate="{required: true, regex: '^\\+?([0-9+]+)$' }" placeholder="mobile" name="mobile" />
                <span class="text-danger font-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
            </div>
        </div>
        <!-- end col -->
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
              <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="data.gender"
                  :options="genderOptions"
                  :aria-describedby="ariaDescribedby"
                  name="radio-gender"
                ></b-form-radio-group>
              </b-form-group>
            </div>
        </div>
        <!-- end col -->
    </div>
    <div class="row">
        <div class="col-12">
           <vue-dropzone id="profileImageDrop" ref="profileImageDrop"
            :use-custom-slot="true" :maxFiles="1">
              <div class="">
                  <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                  <h4>Update Profile Photo.</h4>
                  <span class="text-muted font-13">
                      (Dimension should be 450 x 450 Max: 100kb)
                  </span>
              </div>
          </vue-dropzone>
        </div>
    </div>
    <div class="row justify-content-end mt-3">
        <div class="col-md-6">
          <div class="text-right">
            <button type="button" @click.prevent="updateUser()" class="btn btn-success mt-2">
                <i class="mdi mdi-content-save"></i> Update
            </button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue';

export default {
  components:{
    VueDropzone,
  },
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      data: {},
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    }
  },
  methods: {
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        if(this.collectFiles().length){formData.append('image_file', this.collectFiles()[0]);}
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update',formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.resetForm()
              this.$store.commit('UPDATE_USER_INFO', response.data.data.user)
            }
        })
      })
    },
    patchUser(){
      this.data = {
        first_name: this.activeUser.first_name,
        last_name: this.activeUser.last_name,
        email: this.activeUser.email,
        mobile: this.activeUser.mobile,
        gender: this.activeUser.gender,
      }
    },
    collectFiles(){
        var arrafile = [];
        if(this.$refs.profileImageDrop){
            arrafile = [...this.$refs.profileImageDrop.getAcceptedFiles()];
        }
        return arrafile
    },
    resetForm() {
      this.$refs.profileImageDrop.removeAllFiles();
    },
  },
  created(){
    this.patchUser()
  },
}
</script>
